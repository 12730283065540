import React, { useEffect, useRef } from "react";

//components
import SimpleLanding from "../../components/layouts/simple-landing";
import SEO from "../../components/seo/seo";

const ATMLocator = () => {
  const atmLocator = useRef(null);
  const title = "Find an ATM Near You",
    description =
      "Use WaFd Bank's ATM locator to find a WaFd Bank ATM near you whether you are looking for a bank branch, a surcharge-free ATM, or a deposit-taking ATM.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/locations/atm"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  //To do: make this as reusable custom hook if we need to load external script in body on multiple pages
  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://wafdbanklocator.wave2.io/load.min.js";
    scriptTag.id = "W2L";
    atmLocator.current.appendChild(scriptTag);
  }, []);

  return (
    <SimpleLanding>
      <SEO {...SEOData} />
      <section className="container" style={{ height: "680px" }} ref={atmLocator} />
    </SimpleLanding>
  );
};

export default ATMLocator;
